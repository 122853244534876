<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>
    <div :class="hasIcon(icon)">
      <span
        v-if="iconDir === 'left'"
        @click="changeShowIcon"
        :class="{ pointer: isAppendFunction }"
        class="input-group-text"
      >
        <i :class="getIcon(icon, iconActive)"></i>
      </span>
      <input
        :id="id"
        :type="
          isAppendFunction && showIcon
            ? 'text'
            : !isAppendFunction
            ? type
            : 'password'
        "
        class="form-control"
        :class="getClasses(size, success, error)"
        :name="name"
        :value="modelValue"
        :disabled="disabled"
        @input="$emit('update:modelValue', $event.target.value)"
        :placeholder="placeholder"
        :isRequired="isRequired"
        autocomplete="off"
      />
      <span
        v-if="iconDir === 'right'"
        @click="changeShowIcon"
        :class="{ pointer: isAppendFunction }"
        class="input-group-text"
      >
        <i :class="getIcon(icon, iconActive)"></i>
      </span>
    </div>
    <div v-if="error" class="invalid-feedback">
      {{ errors[0].$message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SoftInputModel",
  props: {
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconActive: {
      type: String,
      default: "",
    },
    iconDir: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Type here...",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    isAppendFunction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showIcon: false,
    };
  },
  methods: {
    getClasses: (size, success, error) => {
      let sizeValue, isValidValue;

      sizeValue = size ? `form-control-${size}` : null;

      if (error) {
        isValidValue = "is-invalid";
      } else if (success) {
        isValidValue = "is-valid";
      } else {
        isValidValue = "";
      }

      return `${sizeValue} ${isValidValue}`;
    },
    getIcon: function (icon, iconActive) {
      return !this.showIcon ? icon : iconActive;
    },
    hasIcon: (icon) => (icon ? "input-group" : null),
    changeShowIcon: function () {
      if (!this.isAppendFunction) return;

      this.showIcon = !this.showIcon;
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
