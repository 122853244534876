<template>
  <navbar :viewsMenu="false" btn-background="bg-gradient-primary" />
  <div
    class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
    }"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-6">
          <h1 class="mt-5 mb-2 text-white">Restablecer Contraseña</h1>
          <p class="text-white text-lead">
            Al restablecer la contraseña le llegará un correo electrónico con él
            encale de cambio de contraseña al correo registrado en el sistema.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="card-body">
            <form role="form" @submit.prevent="handleSubmit">
              <div class="mb-3">
                <soft-input-model
                  id="name"
                  type="text"
                  v-model="username"
                  placeholder="Ingrese el Usuario"
                  aria-label="Ingrese el Usuario"
                  :error="v$.username.$error"
                  :errors="v$.username.$errors"
                  :disabled="loading"
                />
              </div>

              <div class="text-center">
                <soft-button
                  variant="gradient"
                  color="success"
                  full-width
                  class="my-4 mb-2"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Reestablecer
                </soft-button>
              </div>
              <p class="text-sm mt-3 mb-0">
                ¿Deseas volver al inicio de sesión?
                <router-link
                  :to="{ name: 'login' }"
                  class="text-success text-gradient font-weight-bold"
                >
                  Aquí
                </router-link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";

import SoftButton from "@/components/SoftButton.vue";
import SoftInputModel from "@/components/SoftInputModel.vue";

import { onUnmounted, ref, computed } from "vue";
import { useStore } from "vuex";

import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

import axiosInstance from "@/services/api";
import Swal from "sweetalert2";

export default {
  name: "RestablecerPage",
  components: {
    Navbar,
    AppFooter,
    SoftButton,
    SoftInputModel,
  },

  setup() {
    const store = useStore();

    const username = ref("");
    const loading = ref(false);

    const rules = computed(() => ({
      username: {
        required: helpers.withMessage("Usuario es requerido", required),
      },
    }));

    const v$ = useVuelidate(rules, { username });

    store.commit("toggleEveryDisplay");
    store.commit("toggleHideConfig");

    onUnmounted(() => {
      store.commit("toggleEveryDisplay");
      store.commit("toggleHideConfig");
    });

    const handleSubmit = () => {
      v$.value.$touch();

      if (!v$.value.$invalid) {
        loading.value = true;

        axiosInstance
          .post(`/send-email-restar`, { username: username.value })
          .then(({ data }) => {
            const {
              body = `Hemos recibido tu solicitud para restablecer tu contraseña y hemos enviado un enlace de restablecimiento de contraseña a tu dirección de correo electrónico registrada. Por favor, revisa tu bandeja de entrada y sigue las instrucciones para crear una nueva contraseña segura.`,
            } = data;

            Swal.fire({
              text: body,
              icon: "success",
              confirmButtonText: "Aceptar",
            });

            username.value = "";
            v$.value.$reset();
          })
          .catch((error) => {
            const { message } = error?.response?.data?.error || {};
            Swal.fire({
              text:
                message !== null &&
                message !== "" &&
                typeof message !== "undefined"
                  ? message
                  : `Credenciales Incorrectas`,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    return {
      // Properties
      username,
      loading,
      v$,

      // Methods
      handleSubmit,
    };
  },
};
</script>
